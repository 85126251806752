import {
    createMedia,
    createAudioStreamProcess,
    createVideoStreamProcess,
    createAudioMixingProcess,
    AUDIO_CONTENT_HINTS,
    VIDEO_CONTENT_HINTS,
    applyContentHint,
    createGetDisplayMedia,
    isInitial,
} from '@pexip/media';
import {isSendingAudio, isSendingVideo} from '@pexip/infinity';
import type {
    AudioContentHint,
    Segmenters,
    VideoStreamTrackProcessorAPIs,
} from '@pexip/media';
import {denoiseWasm} from '@pexip/denoise/urls';
import type {RenderEffects} from '@pexip/media-processor';
import {
    urls as mpUrls,
    createSegmenter,
    createCanvasTransform,
} from '@pexip/media-processor';
import {isEmpty} from '@pexip/utils';
import {
    StreamQuality,
    createPreviewAudioInputHook,
    createPreviewAudioOutputHook,
    createPreviewControllerHook,
    createPreviewHook,
    createPreviewVideoInputHook,
    currentBrowserName,
    qualityToMediaConstraints,
    isScreenShareSupported,
} from '@pexip/media-components';
import type {
    MediaDeviceInfoLike,
    InputConstraintSet,
} from '@pexip/media-control';
import {
    interpretCurrentFacingMode,
    areMultipleFacingModeSupported,
    toMediaDeviceInfo,
} from '@pexip/media-control';

import {
    config,
    defaultUserConfig,
    shouldEnableVideoProcessing,
} from '../config';
import {mediaSignals} from '../signals/Media.signals';
import {userCustomImageSignal} from '../signals/ImageStore.signals';
import {logger} from '../logger';
import {
    FFT_SIZE,
    UPDATE_FREQUENCY_HZ,
    SILENT_DETECTION_DURATION_S,
    VAD_THROTTLE_MS,
    PROCESSING_WIDTH,
    PROCESSING_HEIGHT,
    SETTINGS_PROCESSING_WIDTH,
    SETTINGS_PROCESSING_HEIGHT,
    FRAME_RATE,
    BACKGROUND_BLUR_AMOUNT,
    FOREGROUND_THRESHOLD,
    EDGE_BLUR_AMOUNT,
    RENDER_EFFECTS,
    BG_IMAGE_URL,
    MASK_COMBINE_RATIO,
    TIME_WAIT_FOR_MUTED_TRACK_RECOVERY_MS,
} from '../constants';
import {applicationConfig} from '../applicationConfig';
import {
    endPresentationSignal,
    presentationStreamSignal,
} from '../signals/Meeting.signals';

import {imageStore} from './Image.service';

const audioProcessor = createAudioStreamProcess({
    shouldEnable: () => applicationConfig.audioProcessing,
    denoiseParams: {
        wasmURL: denoiseWasm.href,
        workletModule: mpUrls.denoise().href,
    },
    fftSize: FFT_SIZE,
    analyzerUpdateFrequency: UPDATE_FREQUENCY_HZ,
    audioSignalDetectionDuration: SILENT_DETECTION_DURATION_S,
    throttleMs: VAD_THROTTLE_MS,
    onAudioSignalDetected: mediaSignals.onSilentDetected.emit,
    onVoiceActivityDetected: mediaSignals.onVAD.emit,
});

const taskVisionURL = new URL(
    './assets/@mediapipe/tasks-vision/wasm/',
    document.baseURI,
);

const taskVisionBasePath = taskVisionURL.pathname;
const selfieSegmenterURL = new URL(
    './assets/@mediapipe/models/selfie_segmenter_landscape.tflite',
    document.baseURI,
);
const modelAsset = {
    path: selfieSegmenterURL.pathname,
    modelName: 'selfie' as const,
};
const delegate = () => config.get('videoProcessingDelegate');
const selfie = createSegmenter(taskVisionBasePath, {
    processingWidth: PROCESSING_WIDTH,
    processingHeight: PROCESSING_HEIGHT,
    modelAsset,
    delegate,
    log: (message, meta) =>
        logger.debug({context: 'media segmenter', meta: meta}, message),
});

/**
 * Subscribe the `ProcessorRestarted` signal to re-request the media if the track
 * is muted. On some weird cases, the processed track is muted after
 * `ProcessorRestarted` and never recovered.
 */
selfie.subscribe('ProcessorRestarted', () => {
    const startTime = performance.now();
    const handleTrackMuted = () => {
        mediaSignals.onStreamTrackUnmutedFinal.remove(handleTrackUnmuted);
        const endTime = performance.now();
        const [track] = mediaService.media?.stream?.getVideoTracks() ?? [];
        if (track?.muted) {
            // The track is muted, so we need to re-request the media
            logger.debug(
                {track, muted: track.muted, startTime, endTime},
                'Track muted after ProcessorRestarted, re-requesting media',
            );
            mediaService.getUserMedia(getDefaultConstraints());
        }
    };
    const handleTrackUnmuted = () => {
        mediaSignals.onStreamTrackUnmutedFinal.remove(handleTrackUnmuted);
        clearTimeout(mutedTrackTimer);
    };
    const mutedTrackTimer = setTimeout(
        handleTrackMuted,
        TIME_WAIT_FOR_MUTED_TRACK_RECOVERY_MS,
    );
    mediaSignals.onStreamTrackUnmutedFinal.add(handleTrackUnmuted);
});

export const mainSegmenters: Partial<Segmenters> = {
    selfie,
};

const chooseVideoProcessorAPI = (): VideoStreamTrackProcessorAPIs => {
    const videoProcessingAPI = config.get('videoProcessingAPI');
    if (videoProcessingAPI === 'stream' || videoProcessingAPI === 'canvas') {
        return videoProcessingAPI;
    }
    return 'stream';
};

export const browserSupportsPtzConstraints = () => {
    const browserSupports = navigator.mediaDevices.getSupportedConstraints();
    return (
        'pan' in browserSupports &&
        'tilt' in browserSupports &&
        'zoom' in browserSupports
    );
};

const supportingVideoProcessing = shouldEnableVideoProcessing();

const renderParams = {
    backgroundBlurAmount: BACKGROUND_BLUR_AMOUNT,
    foregroundThreshold: FOREGROUND_THRESHOLD,
    edgeBlurAmount: EDGE_BLUR_AMOUNT,
    videoSegmentation: RENDER_EFFECTS,
    maskCombineRatio: MASK_COMBINE_RATIO,
    backgroundImageUrl: BG_IMAGE_URL,
    selfManageSegmenter: supportingVideoProcessing,
};

const transformer = createCanvasTransform(selfie, {
    ...renderParams,
});

const videoProcessor =
    supportingVideoProcessing &&
    createVideoStreamProcess({
        trackProcessorAPI: chooseVideoProcessorAPI,
        shouldEnable: () => applicationConfig.videoProcessing,
        segmenters: mainSegmenters,
        transformer,
        processingWidth: PROCESSING_WIDTH,
        processingHeight: PROCESSING_HEIGHT,
        frameRate: FRAME_RATE,
        ...renderParams,
    });

export const getDisplayMedia = createGetDisplayMedia(() => ({
    video: {
        displaySurface: config.get('displaySurface'),
        cursor: config.get('curosrCapture'),
    },
    audio: config.get('captureAudio'),
    surfaceSwitching: config.get('surfaceSwitching'),
    monitorTypeSurfaces: applicationConfig.monitorTypeSurfaces,
    selfBrowserSurface: applicationConfig.selfBrowserSurface,
    systemAudio: applicationConfig.systemAudio,
}));

let currentDisplayMedia: MediaStream | undefined;
export const getCurrentDisplayMedia = () => currentDisplayMedia;

/**
 * Derive the audio content hint based on the presentation MediaStream and the
 * preference from the user.
 *
 * @param stream - The MediaStream for the presentation, e.g. what you get from
 * `getDisplayMedia`
 */
export const deriveAudioContentHintFromPreso = (
    stream: MediaStream | undefined,
) => {
    const contentHint =
        stream &&
        stream.getAudioTracks().length > 0 &&
        config.get('presoContentHint') === VIDEO_CONTENT_HINTS.Motion
            ? AUDIO_CONTENT_HINTS.Music
            : defaultUserConfig.audioContentHint;
    return contentHint;
};

/**
 * What audio features to turn on based on the audio content hint
 *
 * @param hint - Audio content hint
 * @returns audio constraints e.g. echoCancellation, autoGainControl, noiseSuppression & denoise.
 */
export const deriveAudioFeaturesFromAudioContentHint = (
    hint: AudioContentHint,
) => {
    switch (hint) {
        case 'speech': {
            return {
                echoCancellation: config.get('echoCancellation'),
                autoGainControl: config.get('autoGainControl'),
                noiseSuppression:
                    config.get('noiseSuppression') || !config.get('denoise'),
                denoise: config.get('denoise'),
            };
        }
        case 'music': {
            // For an audio track with the value "music", and for constraints
            // echoCancellation, autoGainControl and noiseSuppression apply a default of "false".
            // https://www.w3.org/TR/mst-content-hint/#behavior-of-a-mediastreamtrack
            return {
                echoCancellation: false,
                autoGainControl: false,
                noiseSuppression: false,
                denoise: false,
            };
        }
        default: {
            return {
                echoCancellation: config.get('echoCancellation'),
                autoGainControl: config.get('autoGainControl'),
                noiseSuppression:
                    config.get('noiseSuppression') || !config.get('denoise'),
                denoise: config.get('denoise'),
            };
        }
    }
};

export const setCurrentDisplayMedia = (newDisplayMedia?: MediaStream) => {
    currentDisplayMedia = newDisplayMedia;
    const [audioTrack] = newDisplayMedia?.getAudioTracks() ?? [];
    presentationStreamSignal.emit(newDisplayMedia);
    if (audioTrack) {
        newDisplayMedia
            ?.getVideoTracks()
            .forEach(applyContentHint(config.get('presoContentHint')));

        // if the presentation stream has attached audio, set contentHint to "music"
        const contentHint = deriveAudioContentHintFromPreso(newDisplayMedia);
        void mediaService.media?.applyConstraints({
            audio: {
                mixWithAdditionalMedia: true,
                ...deriveAudioFeaturesFromAudioContentHint(contentHint),
                contentHint,
            },
        });
    }
};
const presentationMixer = createAudioMixingProcess(getCurrentDisplayMedia);

const getFacingMode = (isUserFacing: boolean) =>
    isUserFacing ? 'user' : 'environment';
const isUserFacingMode = (mode: string) => mode === 'user';

const getDefaultConstraints = () => {
    const callType = config.get('callType');
    const requestAudio = isSendingAudio(callType);
    const requestVideo = isSendingVideo(callType);
    const audio: InputConstraintSet | false = requestAudio
        ? {
              sampleRate: 48000,
              echoCancellation: config.get('echoCancellation'),
              autoGainControl: config.get('autoGainControl'),
              noiseSuppression:
                  !config.get('denoise') && config.get('noiseSuppression'),
              denoise: config.get('denoise'),
              vad: config.get('vad'),
              asd: config.get('asd'),
              contentHint: config.get('audioContentHint'),
              mixWithAdditionalMedia: false,
              ...(isEmpty(config.get('audioInput'))
                  ? {}
                  : {device: config.get('audioInput')}),
          }
        : false;
    const fecc = config.get('fecc');
    const video: InputConstraintSet | false = requestVideo
        ? {
              ...qualityToMediaConstraints(getStreamQuality()),
              foregroundThreshold: config.get('foregroundThreshold'),
              backgroundBlurAmount: config.get('backgroundBlurAmount'),
              edgeBlurAmount: config.get('edgeBlurAmount'),
              maskCombineRatio: config.get('maskCombineRatio'),
              frameRate: applicationConfig.frameRate,
              videoSegmentation: config.get('segmentationEffects'),
              videoSegmentationModel: applicationConfig.segmentationModel,
              backgroundImageUrl: config.get('bgImageUrl'),
              facingMode: getFacingMode(config.get('isUserFacing')),
              resizeMode: 'none',
              contentHint: config.get('videoContentHint'),
              ...(isEmpty(config.get('videoInput'))
                  ? {}
                  : {device: config.get('videoInput')}),
              ...(!browserSupportsPtzConstraints()
                  ? {}
                  : {pan: fecc, tilt: fecc, zoom: fecc}),
          }
        : false;
    return {audio, video};
};

export const mediaService = createMedia({
    getMuteState: () => ({
        audio: config.get('isAudioInputMuted'),
        video: config.get('isVideoInputMuted'),
    }),
    signals: mediaSignals,
    audioProcessors: [
        audioProcessor,
        isScreenShareSupported && presentationMixer,
    ].flatMap(processor => (processor ? [processor] : [])),
    videoProcessors: [videoProcessor].flatMap(processor =>
        processor ? [processor] : [],
    ),
    getDefaultConstraints,
});
userCustomImageSignal.add(record => {
    if (!record) {
        return;
    }
    transformer.backgroundImage = imageStore.getBitmapRecord();
});

config.subscribe('backgroundBlurAmount', backgroundBlurAmount => {
    void mediaService.media?.applyConstraints({video: {backgroundBlurAmount}});
});
config.subscribe('foregroundThreshold', foregroundThreshold => {
    void mediaService.media?.applyConstraints({video: {foregroundThreshold}});
});
config.subscribe('edgeBlurAmount', edgeBlurAmount => {
    void mediaService.media?.applyConstraints({video: {edgeBlurAmount}});
});
config.subscribe('bgImageUrl', backgroundImageUrl => {
    void mediaService.media?.applyConstraints({
        video: {backgroundImageUrl},
    });
});
config.subscribe('denoise', denoise => {
    void mediaService.media?.applyConstraints({
        audio: {denoise, noiseSuppression: !denoise},
    });
});
config.subscribe('vad', vad => {
    void mediaService.media?.applyConstraints({
        audio: {vad},
    });
});
config.subscribe('asd', asd => {
    void mediaService.media?.applyConstraints({
        audio: {asd},
    });
});
config.subscribe('fecc', () => {
    if (mediaService.media?.status && !isInitial(mediaService.media.status)) {
        mediaService.getUserMedia(getDefaultConstraints());
    }
});
config.subscribe('bandwidth', () => {
    void mediaService.media?.applyConstraints({
        video: {...qualityToMediaConstraints(getStreamQuality())},
    });
});
config.subscribe('presoContentHint', hint => {
    currentDisplayMedia?.getVideoTracks().forEach(applyContentHint(hint));
    const audioContentHint =
        deriveAudioContentHintFromPreso(currentDisplayMedia);
    void mediaService.media?.applyConstraints({
        audio: {
            ...deriveAudioFeaturesFromAudioContentHint(audioContentHint),
            contentHint: audioContentHint,
        },
    });
});

endPresentationSignal.add(async () => {
    const contentHint = deriveAudioContentHintFromPreso(undefined);
    await mediaService.media?.applyConstraints({
        audio: {
            mixWithAdditionalMedia: false,
            contentHint,
            ...deriveAudioFeaturesFromAudioContentHint(contentHint),
        },
    });
});

export const muteAudio = (muted: boolean, persist?: boolean) => {
    config.set({key: 'isAudioInputMuted', value: muted, persist});
};

config.subscribe('isAudioInputMuted', isMuted =>
    mediaService.media?.muteAudio(isMuted),
);
export const muteVideo = (muted: boolean, persist?: boolean) => {
    config.set({key: 'isVideoInputMuted', value: muted, persist});
};
config.subscribe('isVideoInputMuted', isMuted =>
    mediaService.media?.muteVideo(isMuted),
);

const previewSegmenter = createSegmenter(taskVisionBasePath, {
    processingWidth: SETTINGS_PROCESSING_WIDTH,
    processingHeight: SETTINGS_PROCESSING_HEIGHT,
    modelAsset,
    delegate,
});

export const releaseSegmenter = () => {
    previewSegmenter.close();
    void previewSegmenter.destroy();
    selfie.close();
    void selfie.destroy();
};

window.addEventListener('beforeunload', releaseSegmenter, {once: true});

export const usePreviewController = createPreviewControllerHook(() => {
    const renderParams = {
        width: SETTINGS_PROCESSING_WIDTH,
        height: SETTINGS_PROCESSING_HEIGHT,
        effects: config.get('segmentationEffects'),
        frameRate: applicationConfig.frameRate,
        backgroundBlurAmount: config.get('backgroundBlurAmount'),
        foregroundThreshold: config.get('foregroundThreshold'),
        edgeBlurAmount: config.get('edgeBlurAmount'),
        backgroundImageUrl: config.get('bgImageUrl'),
        maskCombineRatio: config.get('maskCombineRatio'),
        selfManageSegmenter: supportingVideoProcessing,
        backgroundImage: imageStore.getBitmapRecord(),
    };
    const transformer = createCanvasTransform(previewSegmenter, renderParams);
    const unsubscribe = userCustomImageSignal.add(record => {
        if (!record) {
            return;
        }
        transformer.backgroundImage = imageStore.getBitmapRecord();
    });

    return {
        getCurrentDevices: () => mediaService.devices,
        getCurrentMedia: () => mediaService.media,
        updateMainStream: mediaService.getUserMediaAsync,
        mediaSignal: mediaSignals.onMediaChanged,
        onEnded: () => {
            unsubscribe();
        },
        audioProcessors: [],
        videoProcessors: [
            supportingVideoProcessing &&
                createVideoStreamProcess({
                    trackProcessorAPI: chooseVideoProcessorAPI,
                    shouldEnable: () => applicationConfig.videoProcessing,
                    label: 'PreviewStreamController',
                    videoSegmentationModel: applicationConfig.segmentationModel,
                    segmenters: {selfie: previewSegmenter},
                    transformer,
                    ...renderParams,
                    processingWidth: renderParams.width,
                    processingHeight: renderParams.height,
                    videoSegmentation: renderParams.effects,
                }),
        ].flatMap(processor => (processor ? [processor] : [])),
    };
});

export const getStreamQuality = (bandwidth = config.get('bandwidth')) => {
    const [low, medium, high, veryHigh] = applicationConfig.bandwidths;

    switch (bandwidth) {
        case low:
            return StreamQuality.Low;
        case medium:
            return StreamQuality.Medium;
        case high:
            return StreamQuality.High;
        case veryHigh:
            return StreamQuality.VeryHigh;
        default:
            return StreamQuality.Auto;
    }
};

export const setStreamQuality = (streamQuality: StreamQuality) => {
    let value;
    const [low, medium, high, veryHigh] = applicationConfig.bandwidths;

    switch (streamQuality) {
        case StreamQuality.Low:
            value = low;
            break;
        case StreamQuality.Medium:
            value = medium;
            break;
        case StreamQuality.High:
            value = high;
            break;
        case StreamQuality.VeryHigh:
            value = veryHigh;
            break;
        default:
            value = '';
            break;
    }

    if (config.get('bandwidth') !== value) {
        config.set({key: 'bandwidth', value, persist: true});
        return true;
    }
    return false;
};

export const usePreviewStreamQuality = createPreviewHook({
    get: getStreamQuality,
    set: setStreamQuality,
});

export const usePreviewDenoise = createPreviewHook({
    get: () => config.get('denoise'),
    set: (value: boolean) => {
        config.set({key: 'denoise', value, persist: true});
        return false;
    },
});

export const usePreviewPresoContentHint = createPreviewHook({
    get: () => config.get('presoContentHint') === VIDEO_CONTENT_HINTS.Motion,
    set: (value: boolean) => {
        config.set({
            key: 'presoContentHint',
            value: value
                ? VIDEO_CONTENT_HINTS.Motion
                : VIDEO_CONTENT_HINTS.Detail,
            persist: true,
        });
        return false;
    },
});

export const usePreviewFecc = createPreviewHook({
    get: () => config.get('fecc'),
    set: (value: boolean) => {
        if (config.get('fecc') !== value) {
            config.set({key: 'fecc', value, persist: true});
            return true;
        }
        return false;
    },
});

export const usePreviewPreferPrexInMix = createPreviewHook({
    get: () => config.get('preferPresInMix'),
    set: (value: boolean) => {
        config.set({key: 'preferPresInMix', value, persist: true});
        return false;
    },
});

export const usePreviewSegmentationEffects = createPreviewHook({
    get: () => config.get('segmentationEffects'),
    set: (value: RenderEffects) => {
        config.set({key: 'segmentationEffects', value, persist: true});
        return false;
    },
});

export const usePreviewBgImageUrl = createPreviewHook({
    get: () => config.get('bgImageUrl'),
    set: (value: string) => {
        config.set({key: 'bgImageUrl', value, persist: true});
        return false;
    },
});

export const usePreviewAudioOutput = createPreviewAudioOutputHook(
    (value: MediaDeviceInfoLike) =>
        config.set({key: 'audioOutput', value, persist: true}),
);

export const usePreviewAudioInput = createPreviewAudioInputHook({
    get: () => config.get('audioInput'),
    getExpected: () => mediaService.media?.expectedAudioInput,
    set: (value?: MediaDeviceInfoLike) =>
        value &&
        config.set({
            key: 'audioInput',
            value: toMediaDeviceInfo(value),
            persist: true,
        }),
});

export const usePreviewVideoInput = createPreviewVideoInputHook({
    get: () => config.get('videoInput'),
    getExpected: () => mediaService.media?.expectedVideoInput,
    set: (value?: MediaDeviceInfoLike) =>
        value &&
        config.set({
            key: 'videoInput',
            value: toMediaDeviceInfo(value),
            persist: true,
        }),
});

export const enableAudioSignalDetection = () =>
    config.set({key: 'asd', value: true});

export const disableAudioSignalDetection = () =>
    config.set({key: 'asd', value: false});

let cacheFacingModeToggleDetected = false;
export const canShowFacingModeToggle = (
    devices: MediaDeviceInfoLike[],
): boolean => {
    if (cacheFacingModeToggleDetected) {
        return cacheFacingModeToggleDetected;
    }
    cacheFacingModeToggleDetected =
        areMultipleFacingModeSupported(devices) ||
        // Safari translates the device label which makes it unreliable to snoop the label
        currentBrowserName === 'Safari iPad' ||
        currentBrowserName === 'Safari iPhone';
    return cacheFacingModeToggleDetected;
};

export const toggleFacingMode = (track: MediaStreamTrack | undefined) => {
    // FIXME: you probably shouldn't set it in the first place but we do in express flow on mobile
    config.set({
        key: 'videoInput',
        value: undefined,
    });
    const currentFacingMode =
        interpretCurrentFacingMode(track) ??
        (config.get('isUserFacing') ? 'user' : 'environment');
    const isUserFacing = !isUserFacingMode(currentFacingMode);
    config.set({
        key: 'isUserFacing',
        value: isUserFacing,
        persist: true,
    });
    // Do not send a mute request to backend if the user is toggling the camera
    mediaService.getUserMedia({
        audio: true,
        video: {
            facingMode: {
                ideal: getFacingMode(isUserFacing),
            },
        },
    });
};
