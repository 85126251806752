import {
    MediaEventType,
    createIndexedDevices,
    createStreamTrackEventSubscriptions,
    getDevices,
    getInputDevicePermissionState,
    isIndexedDevices,
    isRequestedResolution,
    mergeConstraints,
    subscribe,
} from '@pexip/media-control';
import type {
    IndexedDevices,
    MediaDeviceInfoLike,
    MediaDeviceRequest,
    InputDevicePermission,
} from '@pexip/media-control';
import {createAsyncQueue, isEmpty, assert} from '@pexip/utils';

import type {
    GetUserMediaProcess,
    Media,
    MediaController,
    MediaOptions,
    MediaProps,
    MediaSignals,
    MediaTrack,
} from './types';
import {internalSignals} from './signals';
import {UserMediaStatus} from './types';
import {createModuleLogger, logger} from './logger';
import {
    createGetUserMediaProcess,
    requestUserMediaWithRetry,
} from './userMedia';
import {
    AUDIO_SETTINGS_KEYS,
    MIXING_SETTINGS_KEYS,
    VIDEO_SETTINGS_KEYS,
    buildMedia,
    createMediaProcessor,
    diffSettings,
    getDevicesChanges,
    hasPtzFeature,
    mergeSettings,
    refineMediaConstraints,
} from './utils';
import {getPermissionStatus, isInitialPermissionsGranted} from './status';
import {isMedia} from './typeGuard';
import {updateFeatureProps as getVideoFeatures} from './videoProcessor';
import {updateFeatureProps as getAudioFeatures} from './audioProcessor';
import {updateFeatureProps as getMixingFeatures} from './audioMixingProcessor';
import {GET_USER_MEDIA_TIMEOUT_MS} from './constants';

export interface MediaUpdaterOptions {
    getUserMedia: GetUserMediaProcess;
    getCurrentDevices: () => Promise<IndexedDevices>;
    shouldDiscardMedia: () => boolean;
    onMediaTracksChanged: (media: Media, tracks: MediaTrack[]) => void;
    getInputDevicePermission?: () => Promise<InputDevicePermission>;
    signals?: MediaSignals;
}

export const createMediaUpdater = ({
    getUserMedia,
    getCurrentDevices,
    shouldDiscardMedia: shouldDisgardMedia,
    onMediaTracksChanged,
    getInputDevicePermission = getInputDevicePermissionState,
    signals,
}: MediaUpdaterOptions) => {
    return async (
        constraints: MediaDeviceRequest,
        currentMedia: Media | undefined,
    ) => {
        const currentDevices = await getCurrentDevices();
        const permission = await getInputDevicePermission();
        const requestNothing = !constraints.audio && !constraints.video;
        const permissionRejected =
            permission.audio === 'denied' && permission.video === 'denied';
        if (requestNothing || permissionRejected) {
            for (const track of currentMedia?.getTracks() ?? []) {
                await track.release();
                currentMedia?.removeTrack(track);
            }
            currentMedia?.setOriginalConstraints(constraints);
            const media =
                currentMedia ??
                buildMedia({
                    constraints,
                    permission,
                    devices: currentDevices,
                    status: permissionRejected
                        ? UserMediaStatus.PermissionsRejected
                        : UserMediaStatus.PermissionsGranted,
                    stream: undefined,
                    signals,
                    tracks: [],
                });
            return onMediaTracksChanged(media, []);
        }

        const {audio: prevAudioSettings, video: prevVideoSettings} =
            currentMedia?.getSettings() ?? {};

        const videoFeatures = getVideoFeatures(constraints.video, {});
        const audioFeatures = getAudioFeatures(constraints.audio, {});
        const mixingFeatures = getMixingFeatures(constraints.audio, {});

        const hasRequestedResolution =
            ['blur', 'overlay'].includes(
                videoFeatures.videoSegmentation ?? '',
            ) || // Skip when there is a render effect to modify the video
            currentDevices.size('videoinput') === 0 || // Skip when no authorized video found
            isRequestedResolution(
                constraints.video,
                currentMedia?.getVideoTracks().at(0)?.getSettings(),
            );

        const audioFeaturesChanged = diffSettings(AUDIO_SETTINGS_KEYS)(
            prevAudioSettings,
            audioFeatures,
        );
        const videoFeaturesChanged = diffSettings(VIDEO_SETTINGS_KEYS)(
            prevVideoSettings,
            videoFeatures,
        );
        const mixingFeaturesChanged = diffSettings(MIXING_SETTINGS_KEYS)(
            prevAudioSettings,
            mixingFeatures,
        );
        const ptzFeaturesChanges =
            hasPtzFeature() &&
            diffSettings(['pan', 'tilt', 'zoom'])(
                prevVideoSettings,
                videoFeatures,
            );

        const audioRequest = refineMediaConstraints({
            kind: 'audioinput',
            request: constraints.audio,
            currentDevices,
            permission: permission.audio,
            currentMediaTracks: currentMedia?.getAudioTracks() ?? [],
        });
        const videoRequest = refineMediaConstraints({
            kind: 'videoinput',
            request: constraints.video,
            currentDevices,
            permission: permission.video,
            currentMediaTracks: currentMedia?.getVideoTracks() ?? [],
            force: !hasRequestedResolution || Boolean(ptzFeaturesChanges),
        });

        if (audioRequest !== undefined || videoRequest !== undefined) {
            const media = await getUserMedia({
                constraints: {audio: audioRequest, video: videoRequest},
                originalConstraints: constraints,
                permission,
                currentMedia,
            });
            if (shouldDisgardMedia()) {
                logger.debug('Discard media');
                return await media.release();
            }
            onMediaTracksChanged(media, [
                ...(audioRequest ? media.getAudioTracks() : []),
                ...(videoRequest ? media.getVideoTracks() : []),
            ]);
        }
        const audioDiff =
            constraints.audio &&
            audioRequest === undefined &&
            currentMedia?.getAudioTracks().at(0)
                ? mergeSettings(audioFeaturesChanged, mixingFeaturesChanged)
                : undefined;
        const videoDiff =
            constraints.video &&
            videoRequest === undefined &&
            currentMedia?.getVideoTracks().at(0)
                ? videoFeaturesChanged
                : undefined;
        if (audioDiff || videoDiff) {
            await currentMedia?.applyConstraints({
                audio: audioDiff,
                video: videoDiff,
            });
        }
    };
};

/**
 * Proxy handler for Media Props
 */
const createMediaPropsHandler = (
    signals?: MediaSignals,
): ProxyHandler<MediaProps> => ({
    get: (target, p: keyof MediaProps) => {
        switch (p) {
            default:
                return target[p];
        }
    },
    set: (target, p: keyof MediaProps, value) => {
        if (target[p] === value) {
            return true;
        }
        if (p === 'devices') {
            if (!isIndexedDevices(value)) {
                return false;
            }
            const nextDevices = value;
            const changes = getDevicesChanges(
                target[p].get(),
                nextDevices.get(),
            );
            if (isEmpty(changes.found) && isEmpty(changes.lost)) {
                return true;
            }
        }
        logger.debug(
            {
                oldValue: target[p],
                newValue: value as unknown,
                meta: {
                    module: 'Media',
                    props: target,
                },
            },
            `Update Props[${p}]`,
        );
        switch (p) {
            case 'devices': {
                if (!isIndexedDevices(value)) {
                    return false;
                }
                target[p] = value;
                signals?.onDevicesChanged?.emit(target[p]);
                internalSignals.onDevicesChanged.emit(target[p]);
                return true;
            }
            case 'media': {
                if (!isMedia(value)) {
                    return false;
                }

                const currentStatus = target[p]?.status;
                target[p] = value;
                signals?.onMediaChanged?.emit(value);
                if (currentStatus !== value.status) {
                    signals?.onStatusChanged?.emit(value.status);
                }

                return true;
            }
            case 'updatingMedia': {
                const result = Reflect.set(target, p, value);
                signals?.onUpdatingMedia?.emit(value);
                return result;
            }
            default: {
                return Reflect.set(target, p, value);
            }
        }
    },
});

/**
 * Create an object to interact with the media scream, which is usually used for
 * our main stream.
 *
 * @param options - @see MediaOptions
 */
export const createMedia = ({
    getMuteState,
    signals,
    audioProcessors,
    videoProcessors,
    getDefaultConstraints = () => ({}),
}: MediaOptions): MediaController => {
    const _props: MediaProps = {
        devices: createIndexedDevices([]),
        discardMedia: false,
        updatingMedia: false,
    };
    const props = new Proxy(_props, createMediaPropsHandler(signals));
    const queue = createAsyncQueue({
        timeoutInMS: GET_USER_MEDIA_TIMEOUT_MS,
        handleTimeout: async () => {
            const status = UserMediaStatus.NoDevicesFound;
            if (props.media) {
                props.media.status = status;
            } else {
                const permission = await getInputDevicePermissionState();
                const devices = await getCurrentDevices();
                props.media = buildMedia({
                    constraints: getDefaultConstraints(),
                    permission,
                    devices,
                    status: UserMediaStatus.NoDevicesFound,
                    stream: undefined,
                    signals,
                    tracks: [],
                });
            }
        },
    });

    const logger = createModuleLogger({
        module: 'Media',
        props: _props,
        get mediaTracks() {
            return _props.media?.stream?.getTracks();
        },
    });

    const getCurrentDevices = async () => {
        if (props.devices.size()) {
            return props.devices;
        }
        props.devices = createIndexedDevices(await getDevices());
        return props.devices;
    };

    const syncMuteState = (tracks: MediaTrack[]) => {
        const inputMuted = getMuteState();
        for (const track of tracks) {
            const prevMuted = track.muted;
            switch (track.kind) {
                case 'audioinput': {
                    track.mute(inputMuted.audio);
                    if (prevMuted !== track.muted) {
                        signals.onAudioMuteStateChanged?.emit(track.muted);
                    }
                    break;
                }
                case 'videoinput':
                    track.mute(inputMuted.video);
                    if (prevMuted !== track.muted) {
                        signals.onVideoMuteStateChanged?.emit(track.muted);
                    }
                    break;
                default:
                    break;
            }
        }
    };

    const processMedia = createMediaProcessor({
        audioProcessors,
        videoProcessors,
        onProcessingError(error, track) {
            logger.error({error, track}, 'Failed to process media track');
            assert(props.media);
            switch (track.kind) {
                case 'audioinput':
                    // FIXME: It should not be device-not-found error, will be fixed with https://gitlab.com/pexip/zoo/-/issues/3793
                    props.media.status = UserMediaStatus.AudioDeviceNotFound;
                    break;
                case 'videoinput':
                    // FIXME: It should not be device-not-found error, will be fixed with https://gitlab.com/pexip/zoo/-/issues/3793
                    props.media.status = UserMediaStatus.VideoDeviceNotFound;
                    break;
            }
        },
    });

    const getUserMediaProcess = createGetUserMediaProcess({
        getUserMedia: requestUserMediaWithRetry(getCurrentDevices),
        getCurrentDevices,
        signals,
    });

    const processAndUpdateMedia = async (
        media: Media,
        tracks: MediaTrack[],
    ) => {
        const processedTracks = await processMedia(tracks);
        // Sync mute state to processed tracks
        syncMuteState(processedTracks);
        for (const [idx, track] of processedTracks.entries()) {
            const originTrack = tracks.at(idx);
            assert(
                originTrack,
                'Processed track should always has the original track in the same order',
            );
            // Only replace track when they are not the same
            if (originTrack.id !== track.id) {
                media.removeTrack(originTrack);
                media.addTrack(track);
            }
        }
    };

    const updateMediaProcess = createMediaUpdater({
        signals,
        getUserMedia: getUserMediaProcess,
        getCurrentDevices,
        shouldDiscardMedia: () => props.discardMedia,
        onMediaTracksChanged: (media, tracks) => {
            // Sync mute state to input tracks
            syncMuteState(tracks);
            tracks.forEach(track => {
                if (track.track) {
                    const unsubscribe = createStreamTrackEventSubscriptions(
                        track.track,
                        {
                            ended: track => {
                                signals.onStreamTrackEnded?.emit(track);
                                unsubscribe();
                            },
                            mute: signals.onStreamTrackMuted?.emit,
                            unmute: signals.onStreamTrackUnmuted?.emit,
                        },
                    );
                }
            });
            props.media = media;
            processAndUpdateMedia(media, tracks).catch((error: unknown) => {
                if (error instanceof Error) {
                    logger.error(error, 'Failed to process media');
                }
            });
        },
    });

    /**
     * A function to update the media only when necessary, aka the current setup
     * for the stream is different from the new constraints
     *
     * @param constraints - @see MediaDeviceRequest
     */
    const updateMedia = async (constraints: MediaDeviceRequest) => {
        try {
            props.updatingMedia = true;
            await updateMediaProcess(constraints, props.media);
        } finally {
            props.updatingMedia = false;
        }
    };

    const mergeMediaConstraints = (constraints: MediaDeviceRequest) => {
        const {audio, video} = getDefaultConstraints();
        return {
            audio:
                audio === false
                    ? false
                    : mergeConstraints(audio)(constraints.audio),
            video:
                video === false
                    ? false
                    : mergeConstraints(video)(constraints.video),
        };
    };

    const getUserMediaAsync: MediaController['getUserMediaAsync'] =
        async constraints => {
            queue.enqueue(
                async () =>
                    await updateMedia(mergeMediaConstraints(constraints)),
                false,
            );
            await queue.execute();
        };

    const getUserMedia: MediaController['getUserMedia'] = constraints => {
        queue.enqueue(
            async () => await updateMedia(mergeMediaConstraints(constraints)),
        );
    };

    const tryAndGetUserMedia: MediaController['tryAndGetUserMedia'] =
        constraints => {
            queue.enqueue(async () => {
                const permission = await getInputDevicePermissionState();
                const status = getPermissionStatus(permission);
                if (isInitialPermissionsGranted(status)) {
                    await updateMedia(mergeMediaConstraints(constraints));
                } else {
                    const currentDevices = await getCurrentDevices();
                    props.media = buildMedia({
                        constraints,
                        permission,
                        devices: currentDevices,
                        status,
                        stream: undefined,
                        signals,
                        tracks: [],
                    });
                }
            });
        };

    // Media Control Event handlers
    const handleNoInputs = (_availableDevices: MediaDeviceInfoLike[]) => {
        if (props.media) {
            props.media.status = UserMediaStatus.NoDevicesFound;
        }
    };

    const handleDeviceChange = (devices: MediaDeviceInfoLike[]) => {
        props.devices = createIndexedDevices(devices);
    };

    // Subscribe Media Events for devices
    subscribe(event => {
        switch (event.detail.type) {
            case MediaEventType.NoInputDevices:
                logger.debug(event.detail, 'NoInputDevices emitted');
                handleNoInputs(event.detail.devices);
                break;

            case MediaEventType.DevicesFound:
            case MediaEventType.DevicesLost:
                logger.debug(event.detail, 'DevicesFound/Lost emitted');
                handleDeviceChange([
                    ...event.detail.authorizedDevices,
                    ...event.detail.unauthorizedDevices,
                ]);
                break;
            default:
                break;
        }
    });

    return {
        get updatingMedia() {
            return props.updatingMedia;
        },
        get media() {
            return props.media;
        },

        get devices() {
            return props.devices;
        },

        getUserMedia,
        getUserMediaAsync,
        tryAndGetUserMedia,
    };
};
